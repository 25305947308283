export default [
  "routerPolicyManagement",
  "policyManagement1",
  "policyManagement2",
  "policyManagement3",
  "routerClaimManagement",
  "claimManagement",
  "claimManagement2",
  "claimManagement3",
  "SCDeploymentControl",
  "SCMapEvents",
  "RealTimePositioning",
  "HangingManagement",
  "enquiryManagement1",
  "enquiryManagement2",
  "enquiryManagement3",
  "BigScreenDisplay",
  "BigScreenDisplayEquipment",
  "BigScreenDisplayMechanical",
  "BigScreenDisplayPolicy",
  "BigScrennDisplayService",
  "BigScreenDisplayRisk",
  "BigScreenDisplayRiskVideo",
  "BigScreenDisplayProducts",
  "BigScreenDisplayNewMechanical",
  "BigScreenDisplayCustomerInfo",
  "BigScreenDisplayEquipmentInfo",
  "BigScreenDisplayEquipmentInfoRecord",
  "BigScreenDisplayCooperation",
  "BigScreenDisplayCustomerRecord",
  "DispatchingManagementXY",
  "riskHomeManagement",
  "riskControlManagement",
  "lineUpManagement",
  "DispatchingManagement",
  "VideoPlayback",
  "claimDetails",
  "SecurityMonitoring",
  "commercialVehicleStatisticalReport",
  "partsHomeManagement",
  "partsRetrievalListPage",
  "nonClaimManagement",
  "nonCompanyPolicyManagement",
  "RealTimeVideo",
  "newEnergyClaimManagement",
  "newEnergyClaimList",
  "newEnergyClaimDetails",
  // '空',
];
